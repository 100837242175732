import React from "react";
import { BrowserRouter } from "react-router-dom";
import BaseRouter from "./routes";
import ResponsiveLayout from "./components/Layout";
import TokenRefresh from "./features/authentication/tokenRefresh";
import { Amplify } from "aws-amplify";
import config, { ENVIRONMENT } from "./config";
import { withAuthenticator } from "@aws-amplify/ui-react";

// const awsConfig = config[ENVIRONMENT].awsConfig;
// Amplify.configure(awsConfig);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ResponsiveLayout>
          {/* <TokenRefresh /> */}
          <BaseRouter />
        </ResponsiveLayout>
      </BrowserRouter>
    </div>
  );
}

export default App;
