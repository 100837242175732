import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState, FormEvent } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { FormHelperText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { setAuthTokens, setIsAuthenticated, setUser } from "./authSlice";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import config, { ENVIRONMENT } from "../../config";

// Load Amplify configuration
Amplify.configure({
  Auth: {
    region: config[ENVIRONMENT].awsConfig.Auth.region,
    userPoolId: config[ENVIRONMENT].awsConfig.Auth.userPoolId,
    userPoolWebClientId: config[ENVIRONMENT].awsConfig.Auth.userPoolWebClientId,
    identityPoolId: config[ENVIRONMENT].awsConfig.Auth.identityPoolId,
  },
});

type AWSCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
};

type SignInResult = {
  user: any;
  credentials: AWSCredentials;
};

async function signInAndConfigureAWS(username: string, password: string): Promise<SignInResult> {
  try {
    const user = await Auth.signIn(username, password);
    const credentials = (await Auth.currentCredentials()) as AWSCredentials;
    localStorage.setItem("AWSCreds", JSON.stringify(credentials));
    return { user, credentials };
  } catch (error) {
    throw error;
  }
}

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("email") as string | null;
    const password = data.get("password") as string | null;

    if (!username || !password) {
      setError("Username or password is missing.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { user, credentials } = await signInAndConfigureAWS(username, password);

      dispatch(
        setAuthTokens({
          cognitoTokens: {
            AccessToken: user.signInUserSession.accessToken.jwtToken,
            ExpiresIn: user.signInUserSession.idToken.payload.exp,
            IdToken: user.signInUserSession.idToken.jwtToken,
            RefreshToken: user.signInUserSession.refreshToken.token,
            TokenType: "Bearer",
          },
        })
      );
      dispatch(setIsAuthenticated(true));
      dispatch(
        setUser({
          user: { username, firstName: username, lastName: "" },
        })
      );

      navigate("/profile");
    } catch (error) {
      setError("Invalid username or password. Please try again.");
      console.error("Error during login:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={!!error}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            error={!!error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link to="/reset-password" className="link">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/register" className="link">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
